<template>
    <headers :block="scrolledDistance>100?true:false"></headers>
    <div class="top-gap"></div>
    <!-- 赚取 -->
    <div class="launch">
        <div>
            <div class="title">{{ $t('home.launchTitle') }} <span>USDT</span> </div>
            <div class="desc">{{ $t('home.launchDesc') }}</div>
            <div class="buts">
                <button @click="goPage('lp')">
                    {{ $t('home.but1') }}
                    <img src="../../static/images/arrow.png" >
                </button>
                <button @click="goPage('top')">
                    {{ $t('header.top') }}
                    <img src="../../static/images/arrow.png" >
                </button>
            </div>
            <img class="bg" src="../../static/images/home-launch-bg.png" >
        </div>
    </div>
    <!-- NFT -->
    <div class="nft">
        <div class="introduce">
            <img src="../../static/images/nft-bg.png" >
            <div>
                <div>Arctic Intelligence Story</div>
                <div>{{ $t('home.nft1') }}</div>
                <div>{{ $t('home.nft2') }}</div>
                <button @click="toURL()">{{ $t('home.nftbut') }}</button>
            </div>
        </div>
        <div class="token">
            <div>
                <div>
                    <img src="../../static/images/logo.png" >
                    
                    {{ $t('home.token') }}
                </div>
                <button class="pc-button" @click="goPage('top')">{{ $t('home.tokenBuy') }}</button>
            </div>
            <div class="desc">{{ $t('home.tokenDesc') }}</div>
            <div class="list">
                <div v-for="(item,index) in tokenList" :key="index">
                    <div>{{ $t('home.' + item.name) }}</div>
                    <div class="num">{{ Util.showAmount(item.num) }} {{ item.unit }}</div>
                </div> 
            </div>
            <button class="mobile-button" @click="goPage('top')">{{ $t('home.tokenBuy') }}</button>
        </div>
    </div>
    <!-- handel -->
    <div class="handel">
        <img class="left-icon" src="../../static/images/handel-left.png">
        <img class="right-icon" src="../../static/images/handel-right.png">
        <div class="nft-lay">
            <div>
                <b>{{ $t('home.handelNftTitle') }}</b>
                <p>{{ $t('home.handelNftDesc') }}</p>
            </div>
            <img src="../../static/images/nft.png" >
        </div>
        <div class="art-lay">
            <img src="../../static/images/art.png" >
            <div>
                <b>{{ $t('home.handelArtTitle') }}</b>
                <p>{{ $t('home.handelArtDesc') }}</p>
            </div>
        </div>
        <div class="nft-lay ai">
            <div>
                <b>{{ $t('home.handelAiTitle') }}</b>
                <p>{{ $t('home.handelAiDesc') }}</p>
            </div>
            <img src="../../static/images/ai.png" >
        </div>
    </div>
    <!-- friend -->
    <div class="friend">
        <div>
            <b>{{ $t('home.friend') }}</b>
            <img class="pc-icon" src="../../static/images/friend.png" >
            <img class="mobile-icon" src="../../static/images/friend-m.png" >
        </div>
    </div> 
    <!-- work -->
    <div class="work">
        <div>
            <b>{{ $t('home.workTitle') }}</b>
            <p>{{ $t('home.workDesc') }}</p>
        </div>
        <div class="icon">
            <img :src="require('../../static/images/work-' + (index + 1) + '.png')" v-for="(item,index) in 9" :key="index" />
        </div>
    </div>
    <bases></bases>
</template>
<script setup>
    import headers from '@/components/header'
    import bases from '@/components/base'
    import {computed, reactive, watch, onMounted} from 'vue';
    import Util from "@/utils/common-util";

    import {call, getContractAddress, send} from "@/contract/web3-util";
    import Web3 from "web3";
    import {memberStore} from "@/store/member"
    import {walletStore} from "@/store/wallet"
    import {webStore} from "@/store/web"
    import i18n from "@/i18n";
    import {ElMessage} from "element-plus";
    import {useRouter} from "vue-router";

    const mStore = memberStore()
    const wStore = walletStore()
    const wbStore = webStore()

    const router = new useRouter()

    onMounted(() => {
        getTopInfo()
    })

    async function getTopInfo() {
        const info = await call('stake-v2', 'getTopInfo')
        tokenList[0].num = Web3.utils.fromWei(info.price) - 0
        tokenList[1].num = (Web3.utils.fromWei(info.totalSupply) - 0) *  (Web3.utils.fromWei(info.price) - 0)   
        tokenList[2].num = Web3.utils.fromWei(info.totalSupply) - 0
    }

    const tokenList = reactive([
        {name: 'price', num: '0', unit: 'USDT'},
        {name: 'market', num: '0', unit: 'USDT'},
        {name: 'tokenAll', num: '0', unit: 'ATI'},
    ])

    function toURL(url) {
      if (url) {
        window.open(url, '_blank')
      } else {
        ElMessage({
          message: i18n.global.t('alert.soon'),
          type: 'warning'
        })
      }
    }

    function goPage(url){
      router.push({
        path: url
      })
    }

    function toWhitePaper() {
      let language = localStorage.getItem('locale') || 'cn' ;
      let url = ''
      if (language === 'cn') {
        url = ''
      }
      toURL(url)
    }

    const curAccount = computed(()=>{
      return wStore.curAccount
    })
    watch(curAccount, (newVal, oldVal) =>{
      if (newVal) {
        if (oldVal) {
          alert(i18n.global.t('alert.reLogin'))
        }

        wbStore.accountLogin().then((result) => {
          wbStore.setLoginState(true)
          mStore.queryMemberInfo()

        }).catch((reason) => {
          wbStore.setLoginState(false)
          console.error('login failed')
        })
      }
    })
</script>
<style scoped src="./css/index.css"></style>
